import * as React from "react";
import { useState } from "react";
import { Button, Col, Row } from 'reactstrap';
import { map } from 'lodash-es';
import Page from '../../components/Page/page';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app


const media = [
    {
        url: 'https://www.youtube.com/embed/_BnqEvzY4d4',
        type: 'video'
    }, {
        url: '/images/llama-survival/choose-your-weapon.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/customize-your-weapon.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/fight-bosses.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/power-up.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/save-the-world.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/Cancun.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/Cancun-2.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/Chicago-1.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/Chicago-2.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/Eagles-Nest.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/Eagles-Nest-2.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/Eagles-Nest-3.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/Grapevine.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/Grapevine-2.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/New-York-City-1.jpg',
        type: 'image'
    }, {
        url: '/images/llama-survival/New-York-City-2.jpg',
        type: 'image'
    }
];

const imageMedia = media.filter(item => item.type === 'image');

export const Media = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [index, setIndex] = useState(0);
    return (
        <>
            <Row>
                {map(media, (mediaItem, index) => {
                    if (mediaItem.type === 'image') {
                        return (
                            <Col key={index} xs="12" md="6" lg="4">
                                <Button className={`mt-1 animate__animated animate__fadeInUp animate__faster animate__delay-${index * 250}ms`} key={mediaItem.url} onClick={() => {
                                    setIsOpen(true);
                                    setIndex(index);
                                }}>
                                    <img src={mediaItem.url} />
                                </Button>
                            </Col>
                        );
                    }

                    return (
                        <Col key={index} xs="12" lg="6" style={{ justifyContent: 'center', display: 'flex' }}>
                            <iframe width="560" height="315"
                                src={mediaItem.url}
                                title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                        </Col>
                    );
                    
                })
                }
            </Row>
            {isOpen && (
                <Lightbox
                    mainSrc={imageMedia[index].url}
                    nextSrc={imageMedia[(index + 1) % imageMedia.length].url}
                    prevSrc={imageMedia[(index + imageMedia.length - 1) % imageMedia.length].url}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setIndex((index + imageMedia.length - 1) % imageMedia.length)}
                    onMoveNextRequest={() => setIndex((index + 1) % imageMedia.length)}
                />
            )}
        </>
    );
};


const MediaPage = ({ location }) => {
    return (
        <Page animateHeader titleClassName="text-center" title="Official Media" seoTitle="Official Media" location={location}>
            <Media />
        </Page>
    );
};

export default MediaPage;
